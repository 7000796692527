import * as Sentry from '@sentry/astro';

/**
 * Client-side Sentry set up manually to configure additional integations
 *
 * https://docs.sentry.io/platforms/javascript/guides/astro/manual-setup/#client-side
 */
Sentry.init({
  dsn: 'https://da3ccaec995f28fd59525fb500750070@o4507179246157824.ingest.us.sentry.io/4507179247730688',
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.extraErrorDataIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  environment: process.env.NODE_ENV,
});
